import React from 'react';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate, scaleAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';

const Banner:React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className={[classes.content, 'container'].join(' ')}>
        <motion.img variants={scaleAnimate} custom={1} src="/images/about_banner_img.png" alt="" className={classes.img} />
        <div>
          <motion.h2 variants={bottomAnimate} custom={1} className={classes.title}>
            <Trans
              t={t} i18nKey="aboutPage.banner.title"
              components={{ br: <br />, span: <span /> }}
            />
          </motion.h2>
          <motion.p variants={bottomAnimate} custom={2} className={classes.text}>
            {t('aboutPage.banner.subtitle')}
          </motion.p>
        </div>
      </div>
    </motion.section>
  );
}

export default Banner;