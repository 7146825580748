import React from 'react';
// Framer
import { motion } from 'framer-motion';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';
import { bottomAnimate, leftAnimate, scaleAnimate } from 'utilities/Framer';

const EazyToUse:React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className={[classes.content, 'container'].join(' ')}>
        <motion.img variants={scaleAnimate} custom={1} src="/images/explore_eazy-to-use_img.png" alt="" className={classes.img} />
        <div>
          <div className={classes.titles}>
            <motion.h2 variants={bottomAnimate} custom={1} className={classes.title}>
              <Trans
                t={t} i18nKey="explorePage.eazyToUse.easyToUse"
                components={{ span: <span /> }}
              />
            </motion.h2>
            <motion.h2 variants={bottomAnimate} custom={2} className={classes.title}>
              <Trans
                t={t} i18nKey="explorePage.eazyToUse.easyToLearn"
                components={{ span: <span /> }}
              />
            </motion.h2>
            <motion.h2 variants={bottomAnimate} custom={3} className={classes.title}>
              <Trans
                t={t} i18nKey="explorePage.eazyToUse.easyToScale"
                components={{ span: <span /> }}
              />
            </motion.h2>
          </div>
          <motion.h2 variants={bottomAnimate} custom={1} className={[classes.title, classes['titles--tablet']].join(' ')}>
            <Trans
              t={t} i18nKey="explorePage.eazyToUse.tabletTitle"
              components={{ span: <span /> }}
            />
          </motion.h2>
          <motion.p variants={leftAnimate} custom={4} className={classes.text}>
            <Trans
              t={t} i18nKey="explorePage.eazyToUse.subtitle"
              components={{ br: <br /> }}
            />
          </motion.p>
        </div>
      </div>
    </motion.section>
  );
}

export default EazyToUse;
