import React, { useState } from 'react';
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
// Components
import FeaturesButton, { MFeaturesButton } from './FeaturesButton';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate, leftAnimate, scaleAnimate } from 'utilities/Framer';
// Styles
import "swiper/css";
import "swiper/css/pagination";
import "./swiper.scss";
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';

const Features:React.FC = () => {
  const { t } = useTranslation('common');

  const [activeImage, setActiveImage] = useState(1);

  const imgUrl = `images/features/${activeImage}.png`;

  const handleClick = (id: number) => {
    setActiveImage(id);
  }

  const buttons = [
    { id: 1, title: t('homePage.features.workspaceFilters'), text: t('homePage.features.workspaceFiltersText') },
    { id: 2, title: t('homePage.features.searchFunctionality'), text: t('homePage.features.searchFunctionalityText') },
    { id: 3, title: t('homePage.features.dynamicCalendar'), text: t('homePage.features.dynamicCalendarText') },
    { id: 4, title: t('homePage.features.dynamicTimeline'), text: t('homePage.features.dynamicTimelineText') },
    { id: 5, title: t('homePage.features.dynamicReports'), text: t('homePage.features.dynamicReportsText') },
    { id: 6, title: t('homePage.features.aiChat'), text: t('homePage.features.aiChatText') },
    { id: 7, title: t('homePage.features.automatedTasks'), text: t('homePage.features.automatedTasksText') },
  ];

  return (
    <motion.section
      className={classes.page} data-container="features"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className="container">
        <motion.h2 variants={bottomAnimate} custom={1} className={classes.title}>
          <Trans
            t={t} i18nKey="homePage.features.title"
            components={{ span: <span /> }}
          />
        </motion.h2>
        <motion.p variants={bottomAnimate} custom={2} className={classes.subtitle}>{t('homePage.features.subtitle')}</motion.p>
        <motion.div
          className={classes.content}
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.3 }}
        >
          <motion.img variants={scaleAnimate} custom={3} src={imgUrl} alt="" className={classes.desktop} />
          <div className={classes.buttons}>
            {buttons.map((button, index) => (
              <MFeaturesButton variants={leftAnimate} custom={index + 4} key={button.id} button={button} onClick={handleClick} active={activeImage === button.id} />
            ))}
          </div>
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper" spaceBetween={4} slidesPerView={1} onSlideChange={(swiper) => handleClick(swiper.activeIndex + 1)}>
            {buttons.map(button => (
              <SwiperSlide key={button.id}>
                <FeaturesButton button={button} onClick={handleClick} active={activeImage === button.id} />
              </SwiperSlide>
            ))}
          </Swiper>
        </motion.div>
      </div>
    </motion.section>
  );
}

export default Features;
