import React from 'react';
// Framer
import { motion } from 'framer-motion';
import { leftAnimate, opacityAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
// i18next
import { useTranslation } from 'react-i18next';

const Trust:React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.1 }}
    >
      <div className="container" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
        <motion.div
          className={classes.content}
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.5 }}
        >
          <motion.h2 variants={leftAnimate} custom={1} className={classes.title}>{t('homePage.trust.title')}</motion.h2>
          <motion.p variants={leftAnimate} custom={2} className={classes.subtitle}>{t('homePage.trust.subtitle')}</motion.p>
        </motion.div>
      </div>
      <motion.img variants={opacityAnimate} custom={1} src="/images/Trust.jpg" alt="" className={classes.img} />
    </motion.section>
  );
}

export default Trust;
