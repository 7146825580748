import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const GoogleAnalytics:React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname + location.search
    ReactGA.send({ hitType: "pageview", page });
  }, [location]);


  useEffect(() => {
    ReactGA.initialize("G-72HJHEMPC2");
  }, []);

  return null;
};

export default GoogleAnalytics;
