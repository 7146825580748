import React from 'react';
import { NavLink } from 'react-router-dom';
// components
import LanguageSelect from 'components/LanguageSelect';
// MUI
import { Divider } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

const Footer:React.FC = () => {
  const { t } = useTranslation('common');

  const currentYear = new Date().getFullYear();

  return (
    <footer className={classes.footer}>
      <div className={classes.links}>
        <p className={classes.text}>
          <NavLink to="privacy-policy" rel="nofollow">{t('components.footer.privacyPolicy')}</NavLink>
        </p>
        <p className={classes.text}>
          <NavLink to="security-policy" rel="nofollow">{t('components.footer.securityPolicy')}</NavLink>
        </p>
        <p className={classes.text}>
          <NavLink to="refund-cancellation" rel="nofollow">{t('components.footer.refundPolicy')}</NavLink>
        </p>
        <p className={classes.text}>
          <NavLink to="patent-information" rel="nofollow">{t('components.footer.patentInformation')}</NavLink>
        </p>
      </div>
      <Divider className={classes.divider} />
      <p className={[classes.text, classes.copyright].join(' ')}>{`©${currentYear} Case Chronology®. ${t('components.footer.allRightsReserved')}.`}</p>
      <div className={classes.language_select}>
        <LanguageSelect />
      </div>
    </footer>
  );
}

export default Footer;
