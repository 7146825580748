import React from 'react';
// Framer
import { motion } from 'framer-motion';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';
import { bottomAnimate, opacityAnimate } from 'utilities/Framer';

const Management:React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div className={classes.content}>
          <motion.h2 variants={bottomAnimate} custom={1} className={classes.title}>
            <Trans
              t={t} i18nKey="explorePage.management.title"
              components={{ span: <span /> }}
            />
          </motion.h2>
          <motion.p variants={bottomAnimate} custom={2} className={classes.text}>
            <Trans
              t={t} i18nKey="explorePage.management.subtitle"
              components={{ br: <br /> }}
            />
          </motion.p>
        </div>
      </div>
      <motion.img variants={opacityAnimate} custom={1} src="/images/explore_management_img.jpg" alt="" className={classes.img} />
    </motion.section>
  );
}

export default Management;
