import React, { useEffect } from 'react';
// Framer
import { motion } from 'framer-motion';
import { bottomLeftAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';

const RefundPolicy:React.FC = () => {
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
    >
      <div className="container">
        <motion.h2 variants={bottomLeftAnimate} custom={1} className={classes.title}>Refund/Cancellation policy</motion.h2>

        <h3 className={classes.subtitle}> </h3>
        <p className={classes.text}>Can I get a refund on Case Chronology after uploading documents?</p>
        <p className={classes.text}>In most cases, payments for Case Chronology page uploads are not refundable.</p>
        <p className={classes.text}>If you have an issue with your account or think there’s been an error in billing, please call (813) 393-1262. (813) 393-1262 or email support@casechronology.com</p>

        <h3 className={classes.subtitle}>What happens when I submit a chargeback request?</h3>
        <p className={classes.text}>A chargeback request will cause your team to become locked. The team admin will need to contact Case Chronology to discuss a resolution..</p>
      </div>
    </motion.section>
  );
}

export default RefundPolicy;
