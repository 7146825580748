export const rotateAnimate = {
  hidden: {
    rotate: -360,
    scale: 0,
  },
  visible: (custom: number) => ({
    rotate: 0,
    scale: 1,
    transition: { duration: 0.4, delay: custom * 0.2 }, 
  })
}

export const scaleAnimate = {
  hidden: {
    opacity: 0,
    scale: 0
  },
  visible: (custom: number) => ({
    opacity: 1,
    scale: 1,
    transition: { duration: 0.4, delay: custom * 0.2 }, 
  })
}

export const opacityAnimate = {
  hidden: {
    opacity: 0,
  },
  visible: (custom: number) => ({
    opacity: 1,
    transition: { duration: 0.4, delay: custom * 0.2 }, 
  })
}

export const bottomAnimate = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.4, delay: custom * 0.2 }
  })
}

export const leftAnimate = {
  hidden: {
    opacity: 0,
    x: -100,
  },
  visible: (custom: number) => ({
    opacity: 1,
    x: 0,
    transition: { duration: 0.4, delay: custom * 0.2 }, 
  })
}

export const rightAnimate = {
  hidden: {
    opacity: 0,
    x: 100,
  },
  visible: (custom: number) => ({
    opacity: 1,
    x: 0,
    transition: { duration: 0.4, delay: custom * 0.2 }, 
  })
}

export const bottomLeftAnimate = {
  hidden: {
    opacity: 0,
    x: -100,
    y: 100,
  },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    x: 0,
    transition: { duration: 0.4, delay: custom * 0.2 }, 
  })
}
