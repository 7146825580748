import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// Pages
import HomePage from 'pages/Home';
import AboutPage from 'pages/About';
import ExplorePage from 'pages/Explore';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import SecurityPolicy from 'pages/SecurityPolicy';
import RefundPolicy from 'pages/RefundPolicy';
import PatentInformation from 'pages/PatentInformation';
import ClientArea from 'pages/ClientArea';
import ReleasesPage from 'pages/Releases';

const AppRouting:React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/explore" element={<ExplorePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/client-area" element={<ClientArea />} />

      <Route path="/releases" element={<ReleasesPage />} />
  
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/security-policy" element={<SecurityPolicy />} />
      <Route path="/refund-cancellation" element={<RefundPolicy />} />
      <Route path="/patent-information" element={<PatentInformation />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default AppRouting;
