import { releasesContent } from './content';

import { FC } from 'react';
// Components
import ReleaseItem from './ReleaseItem';
// Styles
import classes from './styles.module.scss';

const ReleasesPage:FC = () => {
  return (
    <section className={classes.page}>
      <div className={`${classes.content} container`}>
        <img src="/images/banner_img.png" alt="" className={classes.img} />
        <div className={classes.data}>
          <h2 className={classes.title}><span>Release</span> History</h2>
          {releasesContent.map(release => (
            <ReleaseItem key={`release-${release.title}`} release={release} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default ReleasesPage;
