import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import classes from './styles.module.scss';


const FeaturesButton:React.FC<any> = forwardRef(({ button, onClick, active }, ref: any) => {
  return (
    <div className={classNames(classes.button, {
      [classes['button--active']]: active
    })} key={button.id} onClick={() => onClick(button.id)} ref={ref}>
      <img
        alt={button.title}
        src={`/images/icons/features/${button.id}${active ? '_active' : ''}.svg`}
        className={classNames(classes.icon, { [classes['icon--active']]: active })}
      />
      <div className={classes.button_content}>
        <h3 className={classNames(classes.button_title, {
          [classes['button_title--active']]: active
        })}>{button.title}</h3>
        {active && <p className={classes.button_text}>{button.text}</p>}
      </div>
    </div>
  )
})

export default React.memo(FeaturesButton);

export const MFeaturesButton = motion(FeaturesButton);
