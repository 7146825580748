import React, { useEffect } from 'react';
// Framer
import { motion } from 'framer-motion';
import { bottomLeftAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';

const PatentInformation:React.FC = () => {
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
    >
      <div className="container">
        <motion.h2 variants={bottomLeftAnimate} custom={1} className={classes.title}>Patent Information</motion.h2>
        
        <h3 className={classes.subtitle}> </h3>
        <p className={classes.text}>United States Patent 11,182,338</p>

      </div>
    </motion.section>
  );
}

export default PatentInformation;
