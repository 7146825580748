// Mui
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#212126',
    },
    secondary: {
      main: '#6464E6',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '26px',
          fontWeight: 700,
          textTransform: 'inherit'
        },
        contained: {
          background: 'linear-gradient(315deg, #7c7cf3 0%, #6464E6 100%)',
          color: 'white',
          transition: 'inherit',
          '&:hover': {
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
          },
          '&:disabled': {
            background: 'rgba(0,0,0,0.12)'
          }
        },
        outlined: {
          color: '#6464E6',
          border: '1px solid #6464E6',
          transition: 'inherit',
          '&:hover': {
            backgroundColor: 'rgba(100, 100, 230, 0.07)',
            borderColor: '#6464E6',
          }
        },
        text: {
          color: '#6464E6',
        }
      },
      variants: [
        {
          props: { color: 'secondary', variant: 'contained' },
          style: {
            background: '#fefefe',
            color: '#6464E6',
            transition: 'inherit',
            '&:hover': {
              background: '#fff',
              boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
            },
            '&:disabled': {
              background: 'rgba(0,0,0,0.12)'
            }
          },
        },
        {
          props: { color: 'secondary', variant: 'outlined' },
          style: {
            color: '#fff',
            border: '1px solid #fff',
            transition: 'inherit',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.15)',
              borderColor: '#fff',
            }
          }
        }
      ]
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFormHelperText-root': {
            marginLeft: 0
          },
          '& label': {
            color: '#9E9EB5'
          },
          '& label.Mui-focused': {
            color: '#6464E6'
          },
          '& label.Mui-disabled': {
            color: '#999'
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#6464E6'
          },
          '& .MuiOutlinedInput-root': {
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '150%',
            letterSpacing: '-0.15px',
            color: '#212126',
            '& fieldset': {
              borderColor: '#9E9EB5'
            },
            '&:hover fieldset': {
              borderColor: '#9E9EB5'
            },
            '&.Mui-focused fieldset': {
              borderColor: '#6464E6'
            },
            '&.Mui-disabled fieldset': {
              borderColor: '#999'
            }
          }
        }
      }
    },
  },
});

export default theme;
