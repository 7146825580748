import React, { useEffect } from 'react';
// Blocks
import WhyUseful from './WhyUseful';
import EazyToUse from './EazyToUse';
import SolvedProblem from './SolvedProblem';
import Trial from './Trial';
import Communication from './Communication';
import Management from './Management';
import MoreBlock from 'components/MoreBlock';
import Trust from './Trust';
import Training from './Training';

const ExplorePage:React.FC = () => {
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <React.Fragment>
      <WhyUseful />
      <EazyToUse />
      <SolvedProblem />
      <Trial />
      <Communication />
      <Management />
      <Training />
      <Trust />
      <MoreBlock />
    </React.Fragment>
  );
}

export default ExplorePage;
