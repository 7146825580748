import React from 'react';
import { NavLink } from 'react-router-dom';
// Hooks
import useScrollIntoView from 'hooks/useScrollIntoView';
import useDialog from 'hooks/useDialog';
// MUI
import { Button, IconButton } from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
// Components
import RequestForm from 'components/RequestForm';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';

const Banner:React.FC = () => {
  const { t } = useTranslation('common');
  const { scrollTo } = useScrollIntoView();

  const { Dialog, openDialog, closeDialog } = useDialog();

  return (
    <React.Fragment>
      <Dialog maxWidth="sm">
        <RequestForm onClose={closeDialog} />
      </Dialog>

      <motion.section
        className={classes.page} data-container="banner"
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.4 }}
      >
        <div className={`${classes.content} container`}>
          <div className={classes.data}>
            <motion.h1 variants={bottomAnimate} custom={1} className={classes.title}>
              <Trans
                t={t} i18nKey="homePage.banner.title"
                components={{ br: <br />, span: <span /> }}
              />
            </motion.h1>
            <motion.p variants={bottomAnimate} custom={2} className={classes.subtitle}>
              {t('homePage.banner.subtitle1')}
            </motion.p>
            <motion.p variants={bottomAnimate} custom={3} className={classes.subtitle}>
              {t('homePage.banner.subtitle2')}
            </motion.p>
            <motion.div variants={bottomAnimate} custom={4} className={classes.buttons}>
              <Button variant="contained" onClick={openDialog}>{t('homePage.banner.requestDemo')}</Button>
              <Button variant="outlined" onClick={openDialog} component={NavLink} to="/explore">{t('homePage.banner.learnMore')}</Button>
            </motion.div>
          </div>
          <img src="/images/banner_img.png" alt="" className={classes.img} />
        </div>
        <IconButton
          onClick={() => scrollTo('technology')}
          size="small"
          sx={{ position: 'absolute', bottom: { xs: '16px', md: '24px' }, left: '50%', transform: 'translateX(-50%)' }}
        >
          <ExpandMoreIcon sx={{ fontSize: { xs: '35px', md: '50px' } }} />
        </IconButton>
      </motion.section>
    </React.Fragment>
  );
}

export default Banner;
