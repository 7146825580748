import React from 'react';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate, leftAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';

const Team:React.FC = () => {
  const { t }  = useTranslation('common');

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className="container">
        <motion.h2 variants={bottomAnimate} custom={1} className={classes.title}>
          <Trans
            t={t} i18nKey="aboutPage.team.title"
            components={{ span: <span /> }}
          />
        </motion.h2>
        <motion.div
          className={classes.items}
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.3 }}
        >
          <motion.div variants={leftAnimate} custom={2} className={classes.item}>
            <img className={classes.item_img} src="/images/team/1.jpg" alt="" />
            <div className={classes.item_data}>
              <h3 className={classes.item_title}>John H. Shim</h3>
              <p className={classes.item_subtitle}>
                <Trans
                  t={t} i18nKey="aboutPage.team.president"
                  components={{ br: <br /> }}
                />
              </p>
              <p className={classes.item_text}>({t('aboutPage.team.parentCompany')})</p>
            </div>
          </motion.div>
          <motion.div variants={leftAnimate} custom={3} className={classes.item}>
            <img className={classes.item_img} src="/images/team/2.jpg" alt="" />
            <div className={classes.item_data}>
              <h3 className={classes.item_title}>Mark Pinson</h3>
              <p className={classes.item_subtitle}>
                <Trans
                  t={t} i18nKey="aboutPage.team.ceo"
                  components={{ br: <br /> }}
                />
              </p>
              <p className={classes.item_text}>({t('aboutPage.team.parentCompany')})</p>
            </div>
          </motion.div>
          <motion.div variants={leftAnimate} custom={4} className={classes.item}>
            <img className={classes.item_img} src="/images/team/3.jpg" alt="" />
            <div className={classes.item_data}>
              <h3 className={classes.item_title}>Kyrylo Zinovyev</h3>
              <p className={classes.item_subtitle}>
                <Trans
                  t={t} i18nKey="aboutPage.team.cto"
                  components={{ br: <br /> }}
                />
              </p>
              <p className={classes.item_text}>({t('aboutPage.team.parentCompany')})</p>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
}

export default Team;
