import { FC } from 'react';
// Models
import IRelease from 'models/Release';
// Styles
import classes from './styles.module.scss';

type Props = {
  release: IRelease
}

const ReleaseItem:FC<Props> = ({ release }) => {
  return (
    <div className={classes.item}>
      <h3 className={classes.itemTitle}>{release.title}</h3>
      <h3 className={classes.itemDate}>{release.date}</h3>
      {release.notes && (
        <div className={classes.block}>
          <h4 className={classes.itemSubtitle}>What’s New</h4>
          <div className={classes.blockContent}>
            {release.notes.map(note => (
              <p className={classes.itemText}>{note}</p>
            ))}
          </div>
        </div>
      )}
      {release.bugFixes && (
        <div className={classes.block}>
          <h4 className={classes.itemSubtitle}>Bug Fixes</h4>
          <div className={classes.blockContent}>
            {release.bugFixes.map(bug => (
              <p className={classes.itemText}>{bug}</p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ReleaseItem;
