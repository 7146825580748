import React from 'react';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate, leftAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';

const WhyUseful:React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3 }}
    >
      <img src="/images/explore_useful_img.jpg" alt="" className={classes.img} />
      <div className="container">
        <div className={classes.content}>
          <motion.h1 variants={bottomAnimate} custom={1} className={classes.title}>
            <Trans
              t={t} i18nKey="explorePage.whyUseful.title"
              components={{ span: <span /> }}
            />
          </motion.h1>
          <div className={classes.items}>
            <motion.div variants={leftAnimate} custom={2} className={classes.item}>
              <img src="/images/icons/why-it-is-useful/1.svg" alt={t('explorePage.whyUseful.controlChanges')} className={classes.item_icon} />
              <div>
                <h3 className={classes.iteem_title}>{t('explorePage.whyUseful.controlChanges')}</h3>
                <p className={classes.item_text}>{t('explorePage.whyUseful.itemText1')}</p>
              </div>
            </motion.div>
            <motion.div variants={leftAnimate} custom={3} className={classes.item}>
              <img src="/images/icons/why-it-is-useful/2.svg" alt={t('explorePage.whyUseful.analyzeRecords')} className={classes.item_icon} />
              <div>
                <h3 className={classes.iteem_title}>{t('explorePage.whyUseful.analyzeRecords')}</h3>
                <p className={classes.item_text}>{t('explorePage.whyUseful.itemText2')}</p>
              </div>
            </motion.div>
            <motion.div variants={leftAnimate} custom={4} className={classes.item}>
              <img src="/images/icons/why-it-is-useful/3.svg" alt={t('explorePage.whyUseful.stayOrganized')} className={classes.item_icon} />
              <div>
                <h3 className={classes.iteem_title}>{t('explorePage.whyUseful.stayOrganized')}</h3>
                <p className={classes.item_text}>{t('explorePage.whyUseful.itemText3')}</p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.section>
  )
}

export default WhyUseful;
