import React from 'react';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
// i18next
import { useTranslation } from 'react-i18next';

const Contacts:React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <motion.section
      className={classes.page} data-container="contact-us"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className="container">
        <motion.h2 variants={bottomAnimate} custom={1} className={classes.title}>{t('homePage.contacts.title')}</motion.h2>
        <motion.div
          className={classes.items}
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.5 }}
        >
          <motion.div variants={bottomAnimate} custom={2} className={classes.item}>
            <img src="/images/icons/contacts/1.svg" alt="" className={classes.icon} />
            <div>
              <h3 className={classes.item_header}>{t('homePage.contacts.address')}</h3>
              <a href="http://maps.google.com/?q=309 State Street East, Suite 203, Oldsmar, FL 34677" target="_blank" rel="noreferrer" className={classes.item_text}>309 State Street East, Suite 203, Oldsmar, FL 34677</a>
            </div>
          </motion.div>
          <motion.div variants={bottomAnimate} custom={3} className={classes.item}>
            <img src="/images/icons/contacts/2.svg" alt="" className={classes.icon} />
            <div>
              <h3 className={classes.item_header}>{t('homePage.contacts.email')}</h3>
              <a href="mailto:contact@casechronology.com" className={classes.item_text}>contact@casechronology.com</a>
            </div>
          </motion.div>
          <motion.div variants={bottomAnimate} custom={4} className={classes.item}>
            <img src="/images/icons/contacts/3.svg" alt="" className={classes.icon} />
            <div>
              <h3 className={classes.item_header}>{t('homePage.contacts.phone')}</h3>
              <a href="tel:+18133931262" className={classes.item_text}>+1 (813)393-1262</a>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
}

export default Contacts;
