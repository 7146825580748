import React from 'react';
// Framer
import { motion } from 'framer-motion';
import { leftAnimate, opacityAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';

const SolvedProblem:React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <motion.img variants={opacityAnimate} custom={2} src="/images/explore_solved-problem_img.jpg" alt="" className={classes.img} />
      <div className="container">
        <div className={classes.content}>
          <motion.h2 variants={leftAnimate} custom={1} className={classes.title}>
            <Trans
              t={t} i18nKey="explorePage.solvedProblem.title"
              components={{ span: <span /> }}
            />
          </motion.h2>
          <motion.p variants={opacityAnimate} custom={2} className={classes.text}>
            <Trans
              t={t} i18nKey="explorePage.solvedProblem.subtitle"
              components={{ br: <br /> }}
            />
          </motion.p>
        </div>
      </div>
    </motion.section>
  );
}

export default SolvedProblem;
