import { FC, useEffect, useState } from 'react';
// Services
import { getCookies, setCookies, deleteCookies } from 'services/Cookies.service'
// Redux
import { useDispatch } from 'react-redux';
import { appActions } from 'store/app/appSlice';
// MUI
import { Button, IconButton } from '@mui/material';
import { CookieOutlined as CookieOutlinedIcon, Close as CloseIcon } from '@mui/icons-material';
// i18next
import { useTranslation } from 'react-i18next';
// Styles
import classes from './styles.module.scss';

const Cookies:FC = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const cookies = getCookies();

  const [show, setShow] = useState(!cookies);

  const handleClick = (value: string) => {
    dispatch(appActions.setGAEnabled(value === 'accepted'));
    deleteCookies();
    setCookies(value);
    setShow(false);
  };

  useEffect(() => {
    dispatch(appActions.setGAEnabled(cookies === 'accepted'));
    // eslint-disable-next-line
  }, [cookies]);

  if ( !show ) return null;
  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <div className={classes.iconWrapper}>
          <CookieOutlinedIcon fontSize="large" sx={{ color: '#fff' }} />
        </div>
        <p className={classes.text}>{t('components.cookies.title')}</p>
      </div>
      <div className={classes.actions}>
        <Button
          onClick={() => handleClick('rejected')}
          sx={{ minWidth: 'max-content', color: '#fff', textTransform: 'uppercase' }}
        >
          {t('components.cookies.reject')}
        </Button>
        <Button
          onClick={() => handleClick('accepted')}
          variant="contained"
          color="secondary"
          sx={{ minWidth: 'max-content', textTransform: 'uppercase' }}
        >
          {t('components.cookies.accept')}
        </Button>
        <IconButton onClick={() => setShow(false)}>
          <CloseIcon sx={{ color: '#fff' }} />
        </IconButton>
      </div>
    </div>
  )
}

export default Cookies;
