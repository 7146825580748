import React from 'react';
// Redux
import { useSelector } from 'react-redux';
import { selectGAEnabled } from 'store/app/appSelectors';
// Components
import Footer from 'components/Footer';
import Header from 'components/Header';
import GoogleAnalytics from 'components/GoogleAnalytics';
import Cookies from 'components/Cookies';

import AppRouting from './App.routing';

const App:React.FC = () => {
  const gaEnabled = useSelector(selectGAEnabled);

  return (
    <React.Fragment>
      <Header />
      <main>
        <AppRouting />
      </main>
      <Footer />
      <Cookies />
      {gaEnabled && (
        <GoogleAnalytics />
      )}
    </React.Fragment>
  );
}

export default App;
