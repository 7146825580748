import React, { useEffect } from 'react';
// Framer
import { motion } from 'framer-motion';
import { bottomLeftAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';

const SecurityPolicy:React.FC = () => {
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
    >
      <div className="container">
        <motion.h2 variants={bottomLeftAnimate} custom={1} className={classes.title}>Security policy</motion.h2>
        
        <h3 className={classes.subtitle}> </h3>
        <p className={classes.text}>Your security is very important to Case Chronology, a DBA of Entangle Media LLC(“the Company”)</p>

        <h3 className={classes.subtitle}>The Company’s Commitment to Data Security</h3>
        <p className={classes.text}>Services and Web sites we sponsor have security measures in place to protect the loss, misuse, and alteration of the information under our control. While we make every effort to ensure the integrity and security of our network and systems, we cannot guarantee that our security measures will prevent third-party “hackers” from illegally obtaining this information.</p>

        <h3 className={classes.subtitle}>Case Portals</h3>
        <p className={classes.text}>All documents uploaded into our portal will be done via our secure (SSL) link to <a href="https://clients.casechronology.com/" target="_blank" rel="noreferrer">https://clients.casechronology.com/.</a> Your documents wil automatically be sent to an even more secure working envrionment <a href="https://cases.casechronology.com/" target="_blank" rel="noreferrer">https://cases.casechronology.com/.</a> Although clients will no longer have access to the actual documents uploaded for security reasons, there will be a log of all the files uploaded.</p>

        <h3 className={classes.subtitle}>How to Access or Modify Your Information</h3>
        <p className={classes.text}>The Company offers Customers the opportunity to access or modify information provided during registration. To access or modify such information contact our Customer Service Department at support@casechronology.com.</p>

        <h3 className={classes.subtitle}>Where to Direct Questions About the Company’s Security Policy</h3>
        <p className={classes.text}>If you have any questions about this Security Policy or the practices described herein, you may contact: Customer Service at support@casechronology.com or 813.513.7447.</p>

        <h3 className={classes.subtitle}>Exceptions</h3>
        <p className={classes.text}>As stated previously in this policy, we will never give out your information without your permission. The only exceptions to this are, when we are required by law, in the good-faith belief that such action is necessary in order to conform to the law, or we must comply with a legal process served on our web site. We may also need to give out your information if one of our partners requires it, but these situations are rare and we will always ask for your explicit permission first.</p>
      </div>
    </motion.section>
  );
}

export default SecurityPolicy;
