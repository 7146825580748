import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
// MUI
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, IconButton, InputLabel, Paper, TextField, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
// utilities
import { isEmail, isRequired } from 'utilities/Validation';
// i18next
import { useTranslation } from 'react-i18next';
// Styles
import classes from './styles.module.scss';

interface IForm {
  name: string;
  email: string;
  phone: string;
  company: string;
  message: string;
}

type Props = {
  onClose: () => void;
}

const RequestForm:React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('common');

  const [loading, setLoading] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);

  const { handleSubmit, control, formState: { errors }, watch } = useForm<IForm>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      company: '',
      message: '',
    }
  });

  const nameWathcer = watch('name');
  const emailWathcer = watch('email');

  const onSubmit = handleSubmit(async (data: any) => {
    setLoading(true);
    const nextData: any = {};
    Object.keys(data).forEach(key => { if(data[key]) nextData[key] = data[key] });

    try {
      const response = await axios.post('https://kwpa6jfbfj7hcdg26lspt6illq0zrdcy.lambda-url.us-east-1.on.aws/', nextData);
      if (response.status !== 200) throw new Error('error');
      setSent(true);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    return () => {
      setSent(false);
    }
  }, []);

  if (sent) return (
    <Paper variant="elevation" elevation={6} className={classes.paper} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <IconButton onClick={onClose} sx={{
        position: 'absolute', top: '16px', right: '16px'
      }}>
        <CloseIcon />
      </IconButton>
      <img src="/images/icons/Done.svg" alt="Done" width="100" height="100" />
      <Typography className={classes.title} sx={{ pt: 4, textAlign: 'center' }}>{t('components.requestForm.confirmationTitle')}</Typography>
      <Typography sx={{ pt: 2.5, fontWeight: 500, fontSize: '16px', color: '#464650', textAlign: 'center' }}>{t('components.requestForm.confirmationSubtitle')}</Typography>
      <Button
        onClick={onClose}
        variant="contained"
        sx={{ width: '200px', marginTop: '48px' }}
      >{t('components.requestForm.ok')}</Button>
    </Paper>
  )

  return (
    <Paper variant="elevation" elevation={6} className={classes.paper}>
      <IconButton onClick={onClose} sx={{
        position: 'absolute', top: '16px', right: '16px'
      }}>
        <CloseIcon />
      </IconButton>
      <Typography className={classes.title}>{t('components.requestForm.requestDemo')}</Typography>
      <form noValidate onSubmit={onSubmit} style={{ paddingTop: '32px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control} name="name"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <React.Fragment>
                  <InputLabel className={classes.label}>{t('components.requestForm.name')} *</InputLabel>
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    placeholder={t('components.requestForm.yourName')}
                    required
                    error={!!errors.name}
                    helperText={errors.name?.message || ''}
                  />
                </React.Fragment>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control} name="email"
              rules={{ required: isRequired, pattern: isEmail }}
              render={({ field }) => (
                <React.Fragment>
                  <InputLabel className={classes.label}>{t('components.requestForm.emailAddress')} *</InputLabel>
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    placeholder="example@email.com"
                    required
                    error={!!errors.email}
                    helperText={errors.email?.message || ''}
                  />
                </React.Fragment>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control} name="phone"
              render={({ field }) => (
                <React.Fragment>
                  <InputLabel className={classes.label}>{t('components.requestForm.phoneNumber')}</InputLabel>
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    placeholder="(XXX) XXX XX XX"
                  />
                </React.Fragment>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control} name="company"
              render={({ field }) => (
                <React.Fragment>
                  <InputLabel className={classes.label}>{t('components.requestForm.company')}</InputLabel>
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    placeholder={t('components.requestForm.yourCompany')}
                  />
                </React.Fragment>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control} name="message"
              render={({ field }) => (
                <React.Fragment>
                  <InputLabel className={classes.label}>{t('components.requestForm.howCanWeHelp')}</InputLabel>
                  <TextField
                    {...field}
                    multiline
                    rows={3}
                    fullWidth
                    size="small"
                    placeholder={`${t('components.requestForm.yourMessage')}...`}
                  />
                </React.Fragment>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton
                disabled={!(!!nameWathcer && !!emailWathcer && !errors.email && !errors.name)}
                loading={loading}
                type="submit"
                variant="contained"
                sx={{ width: { xs: '100%', sm: '200px' } }}
              >{t('components.requestForm.submit')}</LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default RequestForm;
