import React, { useEffect } from 'react';
// Blocks
import Banner from './Banner';
import BuiltOut from './BuiltOut';
import Team from './Team';
import MoreBlock from 'components/MoreBlock';

const AboutPage:React.FC = () => {
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <React.Fragment>
      <Banner />
      <BuiltOut />
      <Team />
      <MoreBlock />
    </React.Fragment>
  );
}

export default AboutPage;
