import React from 'react';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate, leftAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';

const BuiltOut:React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className="container">
        <motion.h2 variants={leftAnimate} custom={1} className={classes.title}>
          <Trans
            t={t} i18nKey="aboutPage.builtOut.title"
            components={{ span: <span /> }}
          />
        </motion.h2>
        <motion.div
          className={classes.items}
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.3 }}
        >
          <motion.div variants={bottomAnimate} custom={2} className={classes.item}>
            <div className={classes.item_content}>
              <img className={classes.item_icon} src="/images/icons/built-out-of-necessity/1.svg" alt={t('aboutPage.builtOut.challenge')} />
              <img className={[classes.item_icon, classes.item_icon_desktop].join(' ')} src="/images/icons/built-out-of-necessity/1_desktop.svg" alt={t('aboutPage.builtOut.challenge')} />
              <h3 className={classes.item_title}>{t('aboutPage.builtOut.challenge')}</h3>
              <p className={classes.item_text}>{t('aboutPage.builtOut.itemText1')}</p>
            </div>
            <img className={[classes.arrow, classes['arrow--desktop']].join(' ')} src="/images/icons/built-out-of-necessity/bottom_arrow.svg" alt="" />
            <img className={classes.arrow} src="/images/icons/built-out-of-necessity/right_arrow.svg" alt="" />
          </motion.div>

          <motion.div variants={bottomAnimate} custom={3} className={classes.item}>
            <img className={[classes.arrow, classes['arrow--desktop']].join(' ')} src="/images/icons/built-out-of-necessity/top_arrow.svg" alt="" />
            <img className={classes.arrow} src="/images/icons/built-out-of-necessity/left_arrow.svg" alt="" />
            <div className={classes.item_content}>
              <img className={classes.item_icon} src="/images/icons/built-out-of-necessity/2.svg" alt={t('aboutPage.builtOut.problem')} />
              <img className={[classes.item_icon, classes.item_icon_desktop].join(' ')} src="/images/icons/built-out-of-necessity/2_desktop.svg" alt={t('aboutPage.builtOut.problem')} />
              <h3 className={classes.item_title}>{t('aboutPage.builtOut.problem')}</h3>
              <p className={classes.item_text}>{t('aboutPage.builtOut.itemText2')}</p>
            </div>
          </motion.div>

          <motion.div variants={bottomAnimate} custom={4} className={classes.item}>
            <div className={classes.item_content}>
              <img className={classes.item_icon} src="/images/icons/built-out-of-necessity/3.svg" alt={t('aboutPage.builtOut.solution')} />
              <img className={[classes.item_icon, classes.item_icon_desktop].join(' ')} src="/images/icons/built-out-of-necessity/3_desktop.svg" alt={t('aboutPage.builtOut.solution')} />
              <h3 className={classes.item_title}>{t('aboutPage.builtOut.solution')}</h3>
              <p className={classes.item_text}>{t('aboutPage.builtOut.itemText3')}</p>
            </div>
            <img className={[classes.arrow, classes['arrow--desktop']].join(' ')} src="/images/icons/built-out-of-necessity/bottom_arrow.svg" alt="" />
            <img className={classes.arrow} src="/images/icons/built-out-of-necessity/right_arrow.svg" alt="" />
          </motion.div>

          <motion.div variants={bottomAnimate} custom={5} className={classes.item}>
            <img className={[classes.arrow, classes['arrow--desktop']].join(' ')} src="/images/icons/built-out-of-necessity/top_arrow.svg" alt="" style={{ visibility: 'hidden' }} />
            <img className={classes.arrow} src="/images/icons/built-out-of-necessity/left_arrow.svg" alt="" style={{ visibility: 'hidden' }} />
            <div className={classes.item_content}>
              <img className={classes.item_icon} src="/images/icons/built-out-of-necessity/4.svg" alt={t('aboutPage.builtOut.result')} />
              <img className={[classes.item_icon, classes.item_icon_desktop].join(' ')} src="/images/icons/built-out-of-necessity/4_desktop.svg" alt={t('aboutPage.builtOut.result')} />
              <h3 className={classes.item_title}>{t('aboutPage.builtOut.result')}</h3>
              <p className={classes.item_text}>{t('aboutPage.builtOut.itemText4')}</p>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
}

export default BuiltOut;
