import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  gaEnabled: boolean;
}

const initialState:IState = {
  gaEnabled: false
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setGAEnabled: (state: IState, action:PayloadAction<boolean>) => {
      state.gaEnabled = action.payload;
    }
  }
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
