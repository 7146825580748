import React, { useState } from 'react';
// i18next
import { useTranslation } from 'react-i18next';
// MUI
import { MenuItem, TextField } from '@mui/material';
import { Language as LanguageIcon } from '@mui/icons-material';

export const countries = [
  { label: 'English', value: 'en' },
  { label: 'Español', value: 'es' },
]

const LanguageSelect:React.FC = () => {
  const { i18n } = useTranslation('common');

  const [lang, setLang] = useState(localStorage.getItem('i18nextLng') || 'en');

  const handleLanguage = (event:any) => {
    const { value:lang } = event.target;
    setLang(lang);
    i18n.changeLanguage(lang);
  }

  return (
    <TextField
      select
      name="language-select"
      size="small"
      margin="none"
      value={lang}
      onChange={handleLanguage}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiOutlinedInput-input': {
        }
      }}
      InputProps={{
        startAdornment: <LanguageIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
      }}
    >
      {countries.map((country) => (
        <MenuItem key={country.value} value={country.value}>{country.label}</MenuItem>
      ))}
    </TextField>
  );
}

export default LanguageSelect;
