import React from 'react';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate, leftAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const Trial:React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div className={classes.content}>
          <motion.h2 variants={bottomAnimate} custom={1} className={classes.title}>{t('explorePage.trial.title')}</motion.h2>
          <div className={classes.items}>
            <motion.div variants={leftAnimate} custom={2} className={classes.item}>
              <img src="/images/icons/deposition-and-trial/1.svg" alt="" className={classes.item_icon} />
              <p className={classes.item_text}>{t('explorePage.trial.itemText1')}</p>
            </motion.div>
            <motion.div variants={leftAnimate} custom={3} className={classes.item}>
              <img src="/images/icons/deposition-and-trial/2.svg" alt="" className={classes.item_icon} />
              <p className={classes.item_text}>{t('explorePage.trial.itemText2')}</p>
            </motion.div>
            <motion.div variants={leftAnimate} custom={4} className={classes.item}>
              <img src="/images/icons/deposition-and-trial/3.svg" alt="" className={classes.item_icon} />
              <p className={classes.item_text}>{t('explorePage.trial.itemText3')}</p>
            </motion.div>
            <motion.div variants={leftAnimate} custom={5} className={classes.item}>
              <img src="/images/icons/deposition-and-trial/4.svg" alt="" className={classes.item_icon} />
              <p className={classes.item_text}>{t('explorePage.trial.itemText4')}</p>
            </motion.div>
          </div>
        </div>
      </div>
      <img src="/images/explore_trial_img.jpg" alt="" className={classes.img} />
    </motion.section>
  );
}

export default Trial;
