import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// Hooks
import useDialog from 'hooks/useDialog';
// Componennts
import RequestForm from 'components/RequestForm';
// Mui
import { Button } from '@mui/material';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate, rotateAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';

const MoreBlock:React.FC = () => {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();

  const { Dialog, openDialog, closeDialog } = useDialog();

  return (
    <React.Fragment>
      <Dialog maxWidth="sm">
        <RequestForm onClose={closeDialog} />
      </Dialog>

      <motion.section
        className={classes.page}
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.3 }}
      >
        <div className="container">
          <motion.h2 variants={rotateAnimate} custom={1} className={classes.title}>
            <Trans
              t={t} i18nKey="components.moreBlock.wantToKnowMore"
              components={{ span: <span /> }}
            />
          </motion.h2>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.5 }}
          >
            <motion.div variants={bottomAnimate} custom={2} className={classes.buttons}>
              {pathname !== '/explore' && (
                <Button
                  component={NavLink} to="/explore"
                  variant="outlined"
                  color="secondary"
                  sx={{ width: '176px' }}
                >{t('components.moreBlock.learnMore')}</Button>
              )}
              <Button
                variant="contained"
                sx={{ width: '176px' }}
                color="secondary"
                onClick={openDialog}
              >{t('components.moreBlock.requestDemo')}</Button>
            </motion.div>
          </motion.div>
        </div>
      </motion.section>
    </React.Fragment>
  );
}

export default MoreBlock;
