import React, { useEffect } from 'react';
// Framer
import { motion } from 'framer-motion';
import { bottomLeftAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';

const PrivacyPolicy:React.FC = () => {
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
    >
      <div className="container">
        <motion.h2 variants={bottomLeftAnimate} custom={1} className={classes.title}>Privacy Policy</motion.h2>

        <h3 className={classes.subtitle}> </h3>
        <p className={classes.text}>Your privacy is very important to Case Chronology, a DBA of Entangle Media LLC(“the Company”). We want to make your experience using our services and on the Internet as enjoyable and rewarding as possible, and we want you to use the Internet’s vast array of information, tools, and opportunities with complete confidence.</p>
        <p className={classes.text}>We have created this Privacy Policy to demonstrate our firm commitment to privacy and security. This Privacy Policy describes how the Company collects information from all end users of the Company’s Internet services (the “Services”) — those who access some of our Services but do not have accounts (“Visitors”) as well as those who pay a service fee to per page fee to the Service (“Customers”) — what we do with the information we collect, and the choices Visitors and Customers have concerning the collection and use of such information. The Company requests that you read this Privacy Policy carefully.</p>

        <h3 className={classes.subtitle}>Introduction</h3>
        <p className={classes.text}>The Company collects information in different ways from Visitors and Customers who access the various parts of our Services and the network of Web sites accessible through our Services. We use this information primarily to provide a customized experience as you use our Services and, generally, do not share this information with third parties. However, we may disclose personal information collected if we have received your permission beforehand or in very special circumstances, such as when we believe that such disclosure is required by law or other special cases described below.</p>
        
        <h3 className={classes.subtitle}>Registration</h3>
        <p className={classes.text}>Customers are asked to provide certain personal information when they sign up for our Services including name, address, telephone number, billing information, and the type of personal computer being used to access the Services. The personal information collected from Customers during the registration process is used to manage each Member’s account (for billing purposes). This information is not shared with third parties, unless expressly stated otherwise or in special circumstances. However, in instances where the Company and a partner jointly promote the Services, the Company may provide the partner certain personal information, such as the name, address, and username of persons who subscribed to the Services as a result of the joint promotion for the sole purpose of allowing us and the partner to assess the results of the promotion. In this instance, personal information may not be used by the partner for any other purpose. The Company may also generate non-identifying and aggregate profiles from personal information Customers provide during registration (such as the total number, but not the names, of Customers). As explained in more detail below, we may use this aggregated and non-identifying information to sell advertisements that appear on the Services.</p>
        
        <h3 className={classes.subtitle}>The Company Partners and Sponsors</h3>
        <p className={classes.text}>Some the Company products and services are offered to Visitors and Customers in conjunction with a non-affiliated partner. To provide Visitors and Customers some of these products and services, the partner may need to collect and maintain personal information. In these instances, we will provide such data collected or transferred to our Partners and Sponsors. Additionally, the Company Customers have co-branded pages that are co-sponsored by non-affiliated partners. The Company will share non-identifying and aggregate information (except as described above), but not personal information, with such partners in order to administer the co-branded products or services offered.</p>

        <h3 className={classes.subtitle}>Online Shopping</h3>
        <p className={classes.text}>At some Web sites, you can purchase products and services or register to receive materials, such as a catalog or new product and service updates. In many cases, you may be asked to provide contact information, such as your name, address, email address, phone number, and credit/debit card information. If you complete an order for someone else, such as an online gift order sent directly to a recipient, you may be asked to provide information about the recipient, such as the recipient’s name, address, and phone number. The Company has no control over the third parties’ use of any personal information you provide when placing such an order. Please exercise care when doing so. If you order products directly from the Company, we will use the personal information you provide only to process that order. We do not share this information with outside parties except to the extent necessary to complete that order.</p>
        
        <h3 className={classes.subtitle}>Online Advertisements</h3>
        <p className={classes.text}>The Company displays our online advertisements. We share aggregated and non-identifying information about our Visitors and Customers collected through the registration process as well as through online surveys and promotions with these advertisers. Additionally, in some instances, we use this aggregated and non-identifying information to deliver tailored advertisements. For instance, an advertiser tells us the audience they want to reach (for example, males between 25 and 55 years of age) and provides us an advertisement tailored to the audience. Based upon the aggregated and non-identifying information we have collected, we may then display the advertisement to the intended audience. The Company does not share personal information about its Visitors or Customers with these advertisers.</p>
        
        <h3 className={classes.subtitle}>Responses to Email Inquiries</h3>
        <p className={classes.text}>When Visitors or Customers send email inquiries to the Company, the return email address is used to answer the email inquiry we receive. The Company does not use the return email address for any other purpose and does not share the return email address with any third party.</p>
        
        <h3 className={classes.subtitle}>Voluntary Customer Surveys</h3>
        <p className={classes.text}>We may periodically conduct both business and individual customer surveys. We encourage our customers to participate in these surveys because they provide us with important information that helps us to improve the types of services we offer and how we provide them to you. Your personal information and responses will remain strictly confidential, even if the survey is conducted by a third party. Participation in our customer surveys is voluntary. We take the information we receive from individuals responding to our Customer Surveys and combine (or aggregate) it with the responses of other the Company customers to create broader, generic responses to the survey questions (such as gender, age, residence, hobbies, education, employment, industry sector, or other demographic information). We then use the aggregated information to improve the quality of our services to you, and to develop new services and products. This aggregated, non-personally identifying information may be shared with third parties.</p>
        
        <h3 className={classes.subtitle}>Special Cases</h3>
        <p className={classes.text}>It is the Company’s policy not to use or share the personal information about Visitors of Customers in ways unrelated to the ones described above without also providing you an opportunity to opt out or otherwise prohibit such unrelated uses. However, the Company may disclose personal information about Visitors or Customers, or information regarding your use of the Services or Web sites accessible through our Services, for any reason if, in our sole discretion, we believe that it is reasonable to do so, including: to satisfy laws, such as the Electronic Communications Privacy Act, regulations, or governmental or legal requests for such information; to disclose information that is necessary to identify, contact, or bring legal action against someone who may be violating our Acceptable Use Policy or other user policies; to operate the Services properly; or to protect the Company and our Customers.</p>
        
        <h3 className={classes.subtitle}>“Cookies” and How the Company Uses Them</h3>
        <p className={classes.text}>A “cookie” is a small data file that can be placed on your hard drive when you visit certain Web sites. The Company may use cookies to collect, store, and sometimes track information for statistical purposes to improve the products and services we provide and to manage our telecommunications networks. If you are a Member and have created your own Personal Start Page, we will use a cookie to save your settings and to provide customizable and personalized services. These cookies do not enable third parties to access any of your customer information. Additionally, be aware that if you visit non-the Company Web sites where you are prompted to log in or that are customizable, you may be required to accept cookies. Advertisers and partners may also use their own cookies. We do not control use of these cookies and expressly disclaim responsibility for information collected through them.</p>
        
        <h3 className={classes.subtitle}>Google Analytics</h3>
        <p className={classes.text}>This site uses the Google analytics service. Google Analytics is a web analysis service provided by Google. Google utilizes the data collected to track and examine the use of Case Chronology.com, to prepare reports on its activities and share them with other Google services. Google may use the data collected to contextualize and personalize the ads of its own advertising network. Personal data collected: Cookie and Usage Data. Place of processing: USA. Find Google’s <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">privacy policy here.</a></p>
        
        <h3 className={classes.subtitle}>Statistics</h3>
        <p className={classes.text}>We use information gathered about you from our site statistics (for example your IP address) to help diagnose problems with our server, and to administer our Web site. We also gather broad demographic information from this data to help us improve our site and make your browsing and purchasing experience more enjoyable. This is not linked to any personally identifiable information, except as necessary to prevent fraud or abuse on our system.</p>
        
        <h3 className={classes.subtitle}>The Company’s Commitment to Children’s Privacy</h3>
        <p className={classes.text}>Protecting children’s privacy is especially important to us. It is our policy to comply with the Children’s Online Privacy Protection Act of 1998 and all other applicable laws. While we make every effort to ensure that these Web sites are fun and age-appropriate for our younger audience, we believe that there is no substitute for proper parental supervision. The Company recommends that children ask a parent for permission before sending personal information to the Company, or to anyone else online.</p>
        
        <h3 className={classes.subtitle}>The Company’s Commitment to Data Security</h3>
        <p className={classes.text}>Services and Web sites we sponsor have security measures in place to protect the loss, misuse, and alteration of the information under our control. While we make every effort to ensure the integrity and security of our network and systems, we cannot guarantee that our security measures will prevent third-party “hackers” from illegally obtaining this information.</p>
        
        <h3 className={classes.subtitle}>How to Access or Modify Your Information</h3>
        <p className={classes.text}>The Company offers Customers the opportunity to access or modify information provided during registration. To access or modify such information contact our Customer Service Department at support@casechronology.com.</p>
        
        <h3 className={classes.subtitle}>Where to Direct Questions About the Company’s Privacy Policy</h3>
        <p className={classes.text}>If you have any questions about this Privacy Policy or the practices described herein, you may contact: Customer Service at support@casechronology.com or 813.513.7447.</p>
        
        <h3 className={classes.subtitle}>Exceptions</h3>
        <p className={classes.text}>As stated previously in this policy, we will never give out your information without your permission. The only exceptions to this are, when we are required by law, in the good-faith belief that such action is necessary in order to conform to the law, or we must comply with a legal process served on our web site. We may also need to give out your information if one of our partners requires it, but these situations are rare and we will always ask for your explicit permission first.</p>
        
        <h3 className={classes.subtitle}>Revisions to This Policy</h3>
        <p className={classes.text}>The Company reserves the right to revise, amend, or modify this policy, our Internet Service Agreement and our other policies and agreements at any time and in any manner. Notice of any revision, amendment, or modification will be posted in accordance with the Internet Service Agreement.</p>
      </div>
    </motion.section>
  );
}

export default PrivacyPolicy;
