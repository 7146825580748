import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
// Hooks
import useScrollIntoView from 'hooks/useScrollIntoView';
import useMenu from 'hooks/useMenu';
// MUI
import { Button, IconButton, Tooltip } from '@mui/material';
import { Close as CloseIcon, Menu as MenuIcon, PersonOutlined as PersonOutlinedIcon } from '@mui/icons-material';
// styles
import classes from './styles.module.scss';
// i18next
import { useTranslation } from 'react-i18next';

import { countries } from 'pages/ClientArea';

interface INavLink {
  id: string;
  label: string;
  to?: string;
}

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 1
};

const Header:React.FC = () => {
  const { t } = useTranslation('common');

  const { scrollTo } = useScrollIntoView();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navLinks:INavLink[] = [
    { id: 'banner', label: 'Main' },
    { id: 'technology', label: t('components.header.ourTechnology') },
    { id: 'security', label: t('components.header.security') },
    { id: 'features', label: t('components.header.mainFeatures') },
    { id: 'users', label: t('components.header.users') },
    { id: 'contact-us', label: t('components.header.contactUs') },
    { id: 'explore', label: t('components.header.explore'), to: '/explore' },
    { id: 'about', label: t('components.header.about'), to: '/about' },
  ];

  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenMenu = () => { setOpen(!open) };

  const observerCallback = useCallback((entries:any) => {
    entries.forEach((entry:any, index:number) => {
      if ( entry.isIntersecting ){
        const id = entry.target.dataset.container;
        if ( id ) setActiveMenuItem(id);
      }
    });
  }, []);

  useLayoutEffect(() => {
    if (pathname !== '/') {
      setActiveMenuItem(null);
      return;
    }

    const blocks = navLinks.map((navLink:INavLink) => document.querySelector(`[data-container="${navLink.id}"]`));
    const observer = new IntersectionObserver(observerCallback, options);
    blocks.forEach((block:Element | null) => {
      if ( block ) observer.observe(block)
    });
    return () => {
      observer.disconnect();
    }
    // eslint-disable-next-line
  }, [pathname]);

  const handleScrollTo = (block: string) => {
    navigate(`/#${block}`);
    setOpen(false);
    setTimeout(() => {
      scrollTo(block);
    }, 100)
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setOpen(false);
  };

  useEffect(() => {
    const body = document.getElementsByTagName('body');
    body[0].className = open ? 'page' : '';
  }, [open]);

  useLayoutEffect(() => {
    const { hash } = window.location;
    if (hash) scrollTo(hash.slice(1));
    // eslint-disable-next-line
  }, [])

  const { Menu, MenuItem, openMenu } = useMenu();

  return (
    <header className={classNames(classes.header, { [classes['header--active']]: open })}>
      <NavLink to="/"><img src="/images/header_logo.svg" alt="Case Chronology" className={classes.logo} onClick={scrollToTop} /></NavLink>
      <nav>
        <ul className={classNames(classes.menu, { [classes['menu--active']]: open })}>
          {navLinks.slice(1).map((navLink: INavLink) => (
            navLink.to ? (
              <li key={navLink.id} className={classNames(classes.item, { [classes['item--active']]: pathname === navLink.to })}>
                <NavLink to={navLink.to} onClick={() => setOpen(false)}>{navLink.label}</NavLink>
              </li>
            ) : (
              <li key={navLink.id} className={classNames(classes.item, { [classes['item--active']]: activeMenuItem === navLink.id })} onClick={() => handleScrollTo(navLink.id)}>{navLink.label}</li>
            )
          ))}
          <a
            href="https://blog.casechronology.com/"
            target="_blank" rel="noreferrer"
            className={classes.item}
          >
            {t('components.header.blog')}
          </a>
        </ul>
      </nav>
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Button
          sx={{ display: { xs: 'none', sm: 'block' } }}
          variant="contained"
          onClick={openMenu}
        >
          {t('components.header.clientArea')}
        </Button>
        <Tooltip title={t('components.header.clientArea')}>
          <IconButton
            color="secondary"
            sx={{ display: { sm: 'none' } }}
            onClick={openMenu}
          >
            <PersonOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Menu>
          {Object.keys(countries).map(key => (
            <MenuItem key={key} component={NavLink} to={`/client-area?country=${key}`}>
              <img src={countries[key].flag} alt={countries[key].name} width="26" style={{ marginRight: '8px' }} />
              {countries[key].name}
            </MenuItem>
          ))}
        </Menu>
        <IconButton onClick={handleOpenMenu} className={classes.menuBtn}>
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </div>
    </header>
  );
}

export default Header;
