import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import common_en from 'translations/en/translation.json';
import common_es from 'translations/es/translation.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    whitelist: ["en", "es"],
    debug: false,
    detection: {
      order: ["localStorage", "cookie"],
      chaches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        common: common_en
      },
      es: {
        common: common_es
      }
    },
  });

export default i18n;
