import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate, leftAnimate, opacityAnimate } from 'utilities/Framer';
// MUI
import { Button } from '@mui/material';
import {
  FileCopyOutlined as FileCopyOutlinedIcon,
  CloudUploadOutlined as CloudUploadOutlinedIcon,
  AssignmentIndOutlined as AssignmentIndOutlinedIcon,
} from '@mui/icons-material';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';

export const countries:Record<string, any> = {
  us: {
    flag: '/images/icons/countries/us.svg',
    name: 'United States'
  },
  ca: {
    flag: '/images/icons/countries/ca.svg',
    name: 'Canada'
  },
  eu: {
    flag: '/images/icons/countries/eu.svg',
    name: 'European Union'
  }
}

const links:Record<string, any> = {
  us: {
    casePortal: "https://cases.casechronology.com",
    clientPortal: "https://clients.casechronology.com/login",
    patientPortal: "https://patients.casechronology.com"
  },
  ca: {
    casePortal: "https://cases.casechronology.ca",
    clientPortal: "https://clients.casechronology.ca/login",
    // patientPortal: "https://patients.casechronology.ca"
  },
  eu: {
    casePortal: "https://cases.casechronology.eu",
    clientPortal: "https://clients.casechronology.eu/login",
    // patientPortal: "https://patients.casechronology.eu"
  },
}

const ClientArea:React.FC = () => {
  const { t } = useTranslation('common');
  
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();

  const country = searchParams.get('country');

  useEffect(() => {
    if (!country || !links[country]) navigate('/client-area?country=us');
    // eslint-disable-next-line
  }, [country])

  if (!country) return null;
  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className="container">
        <motion.div variants={leftAnimate} custom={1} className={classes.titleWrapper}>
          <img src={countries[country].flag} alt={country} />
          <h2 className={classes.title}>
            {t('clientAreaPage.welcome')} <span>{countries[country].name}</span>!
          </h2>
        </motion.div>
        <motion.div
          className={classes.items}
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.3 }}
        >
          <motion.div variants={bottomAnimate} custom={2} className={classes.item}>
            <div>
              <div className={classes.item_icon}>
                <FileCopyOutlinedIcon fontSize="inherit" />
              </div>
              <h3 className={classes.item_title}>
                <Trans
                  t={t} i18nKey="clientAreaPage.casePortal"
                  components={{ br: <br /> }}
                />
              </h3>
              <p className={classes.item_text}>{t('clientAreaPage.casePortalText')}</p>
            </div>
            <Button
              fullWidth
              variant="contained"
              component="a" target="_blank"
              href={links[country].casePortal}
            >
              {t('clientAreaPage.login')}
            </Button>
          </motion.div>

          <motion.div variants={bottomAnimate} custom={3} className={classes.item}>
            <div>
              <div className={classes.item_icon}>
                <CloudUploadOutlinedIcon fontSize="inherit" />
              </div>
              <h3 className={classes.item_title}>
                <Trans
                  t={t} i18nKey="clientAreaPage.clientPortal"
                  components={{ br: <br /> }}
                />
              </h3>
              <p className={classes.item_text}>{t('clientAreaPage.clientPortalText')}</p>
            </div>
            <Button
              fullWidth
              variant="contained"
              component="a" target="_blank"
              href={links[country].clientPortal}
            >
              {t('clientAreaPage.login')}
            </Button>
          </motion.div>

          <motion.div variants={bottomAnimate} custom={4} className={classes.item} style={{ display: links[country].patientPortal ? 'flex' : 'none' }}>
            <div>
              <div className={classes.item_icon}>
                <AssignmentIndOutlinedIcon fontSize="inherit" />
              </div>
              <h3 className={classes.item_title}>{t('clientAreaPage.patientPortal')}</h3>
              <p className={classes.item_text}>{t('clientAreaPage.patientPortalText')}</p>
            </div>
            <Button
              fullWidth
              variant="contained"
              component="a" target="_blank"
              href={links[country].patientPortal}
            >
              {t('clientAreaPage.login')}
            </Button>
          </motion.div>
        </motion.div>
        <motion.div variants={opacityAnimate} custom={5} className={classes.hint}>
          <h4>{t('clientAreaPage.hint')}</h4>
        </motion.div>
      </div>
    </motion.section>
  );
}

export default ClientArea;
