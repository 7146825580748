import React from 'react';
// Framer
import { motion } from 'framer-motion';
// Styles
import classes from './styles.module.scss';
// i18next
import { useTranslation } from 'react-i18next';
import { leftAnimate, scaleAnimate } from 'utilities/Framer';

const Communication:React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className={[classes.content, 'container'].join(' ')}>
        <div>
          <motion.h2 variants={leftAnimate} custom={1} className={classes.title}>{t('explorePage.communication.title')}</motion.h2>
          <motion.p variants={leftAnimate} custom={2} className={classes.text}>{t('explorePage.communication.subtitle')}</motion.p>
        </div>
        <motion.img variants={scaleAnimate} custom={1} src="/images/explore_communication_img.jpg" alt="" className={classes.img} />
      </div>
    </motion.section>
  );
}

export default Communication;
